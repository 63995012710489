import moment from 'moment';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import shortid from 'shortid';
import groupBy from 'lodash/groupBy';
import { isEmpty, isNumber, sum } from 'lodash';
import dayjs from 'dayjs';
import {
  RACE_SHOW,
  RACIAL_DONATION_FUND,
  REGEX_NUMBER_DECIMAL,
  REGEX_NUMBER,
  DETAIL_TITLE,
  DETAIL_COMP,
  EMPLOYEE_INFO,
} from 'app/constants';
import Swal from 'sweetalert2';
import { BiSortAlt2 } from 'react-icons/bi';
import { defaultCommonRoute, navigations } from './app/navigations';
import { navigations as staffNavigations } from './app/staffNavigations';

const UNIT = {
  Monthly: '',
  Daily: '/day',
  Hourly: '/hr',
};

export function getEndDateISO(date) {
  return dayjs(date).endOf('date').toISOString();
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(moment(date, 'DD/MM/YYYY HH:mm:ss')) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

export function initCodeViewer() {
  if (!document) return;
  const pre = document.getElementsByTagName('pre');
  if (!pre.length) return;
  Array.prototype.map.call(pre, (p) => {
    // console.log(p);
    p.classList.add('collapsed');
    p.addEventListener('click', (e) => {
      console.log(e.target);
      e.target.classList.remove('collapsed');
    });
  });

  // pre.map(p => {
  //   console.log(p)
  // })
}

export function formatMoneyWithCurrency(value = 0) {
  let number = 0;
  number = parseFloat(value || 0);
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

export function formatMoneyWithCurrencyV2(value = 0, currencySymbol = '$') {
  let number = 0;
  number = parseFloat(value || 0);

  return `${currencySymbol}${number.toFixed(2)}`;
}

export function formatMoneyWithCurrencyV3(value = 0, currencySymbol = '$') {
  let number = 0;
  number = parseInt(value || 0);

  return `${currencySymbol}${number.toLocaleString()}`;
}

const TYPE = {
  leaves: { color: '#42b8df', classNames: ['text-white'], icon: 'leaves' },
  onLeavesOfCompany: { color: '#42b8df', classNames: ['text-white'], icon: 'onLeavesOfCompany' },
  onLeavesOfDepartment: { color: '#42b8df', classNames: ['text-white'], icon: 'onLeavesOfDepartment' },
  dob: { color: '#994aff', classNames: ['text-white'], icon: 'dob' },
  companyEvent: { color: '#f0b93f', classNames: ['text-white'], icon: 'announcement' },
  holidays: { color: '#36cdc2', classNames: ['text-white'], icon: 'holidays' },
  deskBooking: { color: '#FFB600', classNames: ['text-white'], icon: 'deskBooking' },
  roomBooking: { color: '#FFB600', classNames: ['text-white'], icon: 'roomBooking' },
  checkIn: { color: '#FFB600', classNames: ['text-white'], icon: 'checkIn' },
  noOfDayToPay: { color: '#5856D6', classNames: ['text-white'], icon: '' },
  claims: { color: '#999999', classNames: ['text-white'], icon: 'workpassexpiry' },
  newHires: { color: '#39b337', classNames: ['text-white'], icon: 'onboarding' },
  offBoarding: { color: '#e10c00', classNames: ['text-white'], icon: 'offboarding' },
  workPermitExpiry: { color: '#999999', classNames: ['text-white'], icon: 'workpassexpiry' },
  contractExpired: { color: '#000', classNames: ['text-white'], icon: 'contractExpired' },
};

const checkBirthday = (time, year, type) => {
  if (type === 'dob') return `${year}${time.substr(4)}`;
  return time;
};

function getEvent(type, data, date, start, end, year) {
  const startTime = checkBirthday(start, year, type);
  const endTime = checkBirthday(end, year, type);
  return {
    id: shortid.generate(),
    type: type,
    title: `$ICON${data?.title || data?.holidayName || data?.name || data?.employee?.name || 'No title'}`,
    length: data.length,
    start: startTime || date,
    end: endTime || date,
    classNames: TYPE[type]?.classNames,
    allDay: true,
    color: TYPE[type]?.color,
    icon: TYPE[type]?.icon,
  };
}

export function getFieldName(key, isStart) {
  switch (key) {
    case 'companyEvent':
      return 'publishDate';
    case 'holidays':
      return isStart ? 'fromDate' : 'toDate';
    case 'leaves':
      return isStart ? 'leaveDate' : 'leaveEndDate';
    case 'newHires':
      return isStart ? 'employmentStartDate' : 'employmentStartDate';
    case 'dob':
      return isStart ? 'dob' : 'dob';
    case 'offBoarding':
      return isStart ? 'employmentEndDate' : 'employmentEndDate';
    case 'contractExpired':
      return isStart ? 'employmentEndDate' : 'employmentEndDate';
    case 'workPermitExpiry':
      return isStart ? 'prWorkPassExpiryDate' : 'prWorkPassExpiryDate';
    default:
      return '';
  }
}

export function mapCalendar(data = [], year) {
  const events = [];
  Object.keys(data).forEach(function (key) {
    const fieldName = getFieldName(key, true);
    const dataDate = (data[key] || []).map((item) => ({
      ...item,
      date: dayjs(fieldName ? item[fieldName] : item?.employmentStartDate || item?.publishDate).format('YYYY-MM-DD'),
      start: dayjs(fieldName ? item[fieldName] : item?.employmentStartDate || item?.fromDate || item?.leaveDate).format(
        'YYYY-MM-DD'
      ),
      end: dayjs(
        fieldName ? item[getFieldName(key, false)] : item?.employmentStartDate || item?.toDate || item?.leaveEndDate
      ).format('YYYY-MM-DD'),
    }));

    dataDate.forEach((data) => {
      events.push(getEvent(key, data, data?.date, data?.start, data?.end, year));
    });

    // const eventData = groupBy(dataDate, 'date', 'start', 'end');
    //
    // if (!isEmpty(eventData)) {
    //   Object.keys(eventData).forEach(function (ek) {
    //     eventData[ek].forEach((event) => {
    //       events.push(getEvent(key, event, ek));
    //     });
    //   });
    // }
  });

  events.forEach((event, index) => {
    if (event.start !== event.end) {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      const dateArray = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      const _datas = dateArray.map((date) => dayjs(date).format('YYYY-MM-DD'));
      _datas.forEach((date) => {
        events.push({ ...event, start: date, end: date });
      });
    }
  });

  const newEvents = events.filter((event) => event.start === event.end);

  return {
    dates: [],
    events: newEvents,
  };
}

export const getTotalOther = (data = {}) => {
  const result = [];
  Object.keys(data).forEach(function (key) {
    if (key !== 'value') {
      result.push({
        description: key,
        amount: data[key],
      });
    }
  });
  return result;
};

export const getLinkFile = (fileName = '') => {
  if (!fileName) return '';
  if ((fileName || '').includes('http')) return fileName;
  if ((fileName || '').startsWith('/assets')) return fileName;
  return `${process.env.REACT_APP_BASE_URL}/${fileName}`;
};

export const getChatUser = (user) => {
  const room = (user?.rooms || []).find((r) => r?.isPrivate);
  return { ...user, room, ...(user?.user || {}) };
};

export const getTimeIsoString = (time = '00:00') => {
  const times = time.split(':');
  const result = dayjs().hour(times[0]).minute(times[1]).toISOString();

  return (result || '').substr(11, 5);
};

export const toLocalTime = (time = '00:00') => {
  const times = time.split(':');
  const offset = new Date().getTimezoneOffset() / 60;
  let hours = parseInt(times[0]) - offset;
  if (hours > 24) hours = hours - 24;
  if (hours === 24) hours = 12;
  if (hours < 10) return `0${hours}:${times[1]}`;
  return `${hours}:${times[1]}`;
};

export const toLocalTimeForView = (time = '00:00') => {
  const times = time.split(':');
  const offset = new Date().getTimezoneOffset() / 60;
  let hours = parseInt(times[0]) - offset;
  if (hours > 24) hours = hours - 24;
  if (hours === 24) hours = 0;
  if (hours < 10) return `0${hours}:${times[1]}`;
  return `${hours}:${times[1]}`;
};

export const getDateToIsoString = (date, time = '10:00') => {
  const times = time.split(':');
  const result = dayjs(date).hour(times[0]).toISOString();
  return result;
};

export const getDateTimeToIsoString = (date, time = '00:00') => {
  const times = time.split(':');
  const result = dayjs(date).hour(times[0]).minute(times[1]).toISOString();
  return result;
};

export const getWorkYear = (date) => {
  const years = dayjs().diff(dayjs(date), 'year');
  return years;
};

export const getFilename = (url) => {
  const name = url.split('/uploads/');
  return name[1] || 'file-download';
};

export const downloadImage = (url, name) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('An error sorry'));
};

export const capitalizeFLetter = (string) => {
  if (!string) {
    return '';
  }
  return string.replace(/^./, string[0].toUpperCase());
};

export const extractEmployeeNo = (employeeNo = '') => {
  let result = employeeNo ? `${employeeNo}` : '';
  result = (result.match(/\d+/) || [])[0] || '';
  return result.toString().padStart(6, '0');
};

export const calculateStringSimilarity = (str1, str2) => {
  const words1 = str1.split(', ').map((word) => word.toLowerCase());
  const words2 = str2.split(', ').map((word) => word.toLowerCase());
  const commonWords = words1.filter((word) => words2.includes(word)).length;
  return commonWords / Math.max(words1.length, words2.length);
};

export function hasDuplicates(arr) {
  const valueSet = new Set();

  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];

    if (valueSet.has(value)) {
      return true;
    }

    valueSet.add(value);
  }

  return false;
}

export const sortDates = (startDate, endDate, order) => {
  const date1 = moment(startDate, 'DD/MM/YYYY');
  const date2 = moment(endDate, 'DD/MM/YYYY');
  let compareResult = 0;
  if (date1.isBefore(date2)) {
    compareResult = -1;
  } else if (date1.isAfter(date2)) {
    compareResult = 1;
  }
  return order === 'asc' ? -compareResult : compareResult;
};

export const sortString = (a, b, order) => {
  if (order === 'asc') {
    return a.key > b.key ? 1 : -1;
  }
  return a.key > b.key ? -1 : 1; // desc
};

export const getWorkSchedulesData = (data = []) => {
  let workSchedulesData = data;
  if (!isArray(workSchedulesData) && workSchedulesData) {
    const {
      monday = {},
      tuesday = {},
      wednesday = {},
      thursday = {},
      friday = {},
      saturday = {},
      sunday = {},
    } = workSchedulesData;

    workSchedulesData = [
      { ...monday, day: 'Monday', breakDuration: monday?.breakDuration || 0 },
      { ...tuesday, day: 'Tuesday', breakDuration: tuesday?.breakDuration || 0 },
      { ...wednesday, day: 'Wednesday', breakDuration: wednesday?.breakDuration || 0 },
      { ...thursday, day: 'Thursday', breakDuration: thursday?.breakDuration || 0 },
      { ...friday, day: 'Friday', breakDuration: friday?.breakDuration || 0 },
      { ...saturday, day: 'Saturday', breakDuration: saturday?.breakDuration || 0 },
      { ...sunday, day: 'Sunday', breakDuration: sunday?.breakDuration || 0 },
    ];
  }
  return workSchedulesData.map((item) => ({ ...item, offDayType: item.restDay ? 'restDay' : 'offDay' }));
};

export const RACIAL_FOREIGNER_COUNTRIES = ['Chinese', 'Eurasian'];

export const RACIAL_FOREIGNER_TYPES = ['PermanentResident'];

export const getRacialFund = (countryOfResidence, race, religion = '', citizenship = '', foreignerType = '') => {
  let racialFunds = [
    {
      value: 'excludeSDL',
      label: 'Exclude from Skills Development Levy (SDL)',
    },
  ];
  var typeShg = '';
  if (
    countryOfResidence === 'Singapore' &&
    citizenship === 'Singaporean' &&
    (!foreignerType || foreignerType === '' || foreignerType === 'Nil') && //Nil
    race === 'Chinese'
  ) {
    typeShg = 'Chinese';
  }
  if (
    countryOfResidence === 'Singapore' &&
    foreignerType &&
    foreignerType === 'PermanentResident' &&
    race === 'Chinese'
  ) {
    typeShg = 'Chinese';
  }

  if (
    countryOfResidence === 'Singapore' &&
    citizenship === 'Singaporean' &&
    (!foreignerType || foreignerType === '' || foreignerType === 'Nil') && //Nil
    race === 'Eurasian'
  ) {
    typeShg = 'Eurasian';
  }
  if (
    countryOfResidence === 'Singapore' &&
    foreignerType &&
    foreignerType === 'PermanentResident' &&
    race === 'Eurasian'
  ) {
    typeShg = 'Eurasian';
  }

  if (
    countryOfResidence === 'Singapore' &&
    citizenship === 'Singaporean' &&
    (!foreignerType || foreignerType === '' || foreignerType === 'Nil') && //Nil
    race === 'Indian'
  ) {
    typeShg = 'Indian';
  }
  if (
    countryOfResidence === 'Singapore' &&
    foreignerType &&
    (foreignerType === 'PermanentResident' || foreignerType === 'EmploymentPass') &&
    race === 'Indian'
  ) {
    typeShg = 'Indian';
  }

  if (typeShg === '' && countryOfResidence === 'Singapore' && religion === 'Muslim') {
    typeShg = 'Malay';
  }

  if (typeShg !== '') {
    racialFunds.push({
      value: 'fixedCDACAmount',
      label: `Set a fixed ${RACIAL_DONATION_FUND[typeShg]} amount`,
    });
    racialFunds.push({
      value: 'optedOutCDACDonations',
      label: `Opted out from ${RACIAL_DONATION_FUND[typeShg]} donations`,
    });
  }
  return racialFunds;
};

export const checkCdacAmountShow = (race, option = []) => {
  if (!option.includes('optedOutCDACDonations') && option.includes('fixedCDACAmount')) return true;
  return false;
};

export const disableCdacAmount = (race, option = [], value) => {
  if (RACE_SHOW.includes(race) && option.includes('optedOutCDACDonations') && value === 'fixedCDACAmount') {
    return true;
  }
  return false;
};

export const disableFixedCDACAmount = (values, key) => {
  const { race, optedOutCDACDonations } = values || {};
  if (RACE_SHOW.includes(race) && optedOutCDACDonations && key === 'fixedCDACAmount') {
    return true;
  }
  return false;
};

export const checkFixedCDACAmount = (values = {}) => {
  const { race, optedOutCDACDonations, fixedCDACAmount } = values;
  if (!optedOutCDACDonations && fixedCDACAmount) return true;
  return false;
};

export const stringToNumber = (num = '') => {
  if (num === '0,00') return 0;
  if (num && REGEX_NUMBER_DECIMAL.test(num)) {
    let newNum = num.toString().replace(/,/g, '').replace('.00', '');
    return newNum;
  }
  return num;
};

export const numberToString = (num = '') => {
  if (REGEX_NUMBER_DECIMAL.test(num)) {
    return parseFloat(num)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return num;
};

export const getMessageTime = (date, link = '') => {
  if (!date) return '';
  const time = dayjs(date).format('HH:mm');
  const startOfWeek = dayjs().day(0).format('YYYY-MM-DD');
  if (dayjs(date).isSame(dayjs(), 'date')) return `${link} ${time}`;
  if (dayjs(date).isSame(startOfWeek, 'week')) return `${dayjs(date).format('DD/MM/YYYY')} ${link} ${time}`;
  return `${dayjs(date).format('DD/MM/YYYY')} ${link} ${time}`;
};

export const getMessageDateTime = (date, link = '') => {
  if (!date) return '';
  const time = dayjs(date).format('HH:mm');
  const startOfWeek = dayjs().day(0).format('YYYY-MM-DD');
  if (dayjs(date).isSame(dayjs(), 'date')) return `${link} ${time}`;
  if (dayjs(date).isSame(startOfWeek, 'week')) return `${dayjs(date).format('DD/MM/YYYY')} ${link} ${time}`;
  return `${dayjs(date).format('DD/MM/YYYY')} ${link} ${time}`;
};

export const getAge = (birthday) => (birthday ? dayjs().diff(dayjs(birthday), 'y') || 1 : '');

export const getTimesValue = (times = '') => {
  if (!times) return null;
  return dayjs(times, 'HH:mm');
};

export const checkNumber = (val) => {
  const num = Number(val);
  return isNumber(num);
};

const updateOvertimeItems = (payItems, overtimeItems, workingTime) => {
  let updatedOvertimeItems = overtimeItems?.map((overtimeItem) => {
    let payItem = payItems?.find((payItem) => payItem.id === overtimeItem.id);

    if (payItem) {
      let rate = 0; // Default value set to 0

      switch (payItem.id) {
        case 'M8a':
          rate = workingTime?.workingDatesInRange?.overTimeRateHourly || 0;
          break;
        case 'M8b':
          rate = workingTime?.workingDatesInRange?.overTimeOffDayRateHourly || 0;
          break;
        case 'M8c':
          rate = workingTime?.workingDatesInRange?.overTimeRestDayRateHourly || 0;
          break;
        case 'M8d':
          rate = workingTime?.workingDatesInRange?.overTimeHolidayRateHourly || 0;
          break;
        default:
          // Default rate is already set to 0 above.
          break;
      }
      const totalAmount = formatFixedNumber(payItem.value * rate);

      return {
        ...overtimeItem,
        amount: totalAmount,
        value: payItem.value,
        defaultAmount: payItem.defaultAmount,
      };
    }

    return overtimeItem;
  });

  return updatedOvertimeItems;
};

export function getBaseSalaryPerHour(payItem0) {
  return +payItem0?.baseSalaryHourly || 0;
}

export const getPayItemDefault = (data = [], workingTime = {}, type, isAttendance, employeeId, originData = {}) => {
  const payItems = data.filter((item) => {
    if (['M2', 'M2a', 'M2b', 'M3', 'M4', 'M10A', 'L4'].includes(item?.id)) return true;
    if (item.id === 'M6') return false;
    if (['M8', 'M8a', 'M8b', 'M8c', 'M8d'].includes(item?.id)) return false;
    if (item.value === '0' || item.value === 'false') return false;
    return true;
  });
  const baseSalary = Number(payItems?.[0].value || 0);
  let baseSalaryHourly = 0;
  const totalHours = workingTime?.workingDatesInRange?.totalHous || 0;
  const workingHours = originData?.defaultWorkSchedule?.workingHours || 8;

  if (type === 'Daily') {
    baseSalaryHourly = baseSalary / workingHours;
  } else if (type === 'Hourly') {
    baseSalaryHourly = baseSalary;
  } else {
    baseSalaryHourly = totalHours ? baseSalary / totalHours : 0;
  }

  const payItemOverTime = data.filter((item) => {
    if (['M8a', 'M8b', 'M8c', 'M8d'].includes(item?.id)) return true;
  });

  const extractRateSuffix = (rateType) => {
    const match = rateType?.match(/^(\d+(\.\d+)?x)/); // Matches the beginning number followed by 'x'
    return match ? match[1] : '';
  };

  let overtimeItems = [
    {
      id: 'M8a',
      name: 'Normal Working Day',
      cpf: '',
      category: '',
      amount: 0,
      value: 0,
      defaultAmount: 0,
      displayType: `supitem${employeeId}`,
      displayCategory: 'overtime',
    },
    {
      id: 'M8b',
      name: 'Off Day',
      cpf: '',
      category: '',
      amount: 0,
      value: 0,
      defaultAmount: 0,
      displayType: `supitem${employeeId}`,
      displayCategory: 'overtime',
    },
    {
      id: 'M8c',
      name: 'Rest Day',
      cpf: '',
      category: '',
      amount: 0,
      value: 0,
      defaultAmount: 0,
      displayType: `supitem${employeeId}`,
      displayCategory: 'overtime',
    },
    {
      id: 'M8d',
      name: 'Holiday',
      cpf: '',
      category: '',
      amount: 0,
      value: 0,
      defaultAmount: 0,
      displayType: `supitem${employeeId}`,
      displayCategory: 'overtime',
    },
  ];

  const rateSuffix = extractRateSuffix(originData?.normalWorkingDayOvertimeRateType);
  if (rateSuffix) {
    overtimeItems[0].name += ` @ ${rateSuffix}`;
  }

  const rateOffSuffix = extractRateSuffix(originData?.nonWorkingDayOvertimeRateType);
  if (rateOffSuffix) {
    overtimeItems[1].name += ` @ ${rateOffSuffix}`;
  }

  const rateRestSuffix = extractRateSuffix(originData?.nonWorkingDayRestDayOvertimeRateType);
  if (rateRestSuffix) {
    overtimeItems[2].name += ` @ ${rateRestSuffix}`;
  }

  const rateHolidaySuffix = extractRateSuffix(originData?.holidayOvertimeRateType);
  if (rateHolidaySuffix) {
    overtimeItems[3].name += ` @ ${rateHolidaySuffix}`;
  }

  overtimeItems = updateOvertimeItems(payItemOverTime, overtimeItems, workingTime);

  const result = payItems.map(({ name, cpf = '', category, id, value }) => {
    let amount = checkNumber(value) ? Number(value) : 0;
    let fixedAmount = amount;
    let defaultAmount = amount || 1;
    let itemName = name;
    let defaultValue = value;

    if (id === 'M1') {
      itemName = `${type} Salary @ ${formatMoneyWithCurrency(defaultAmount)}`;

      //itemName = `Monthly Salary @ ${formatMoneyWithCurrency(defaultAmount)}`;
      if (type === 'Hourly') {
        value = workingTime?.workingDatesInRange.actualHous;
        defaultValue = value;
        defaultAmount = amount;
        fixedAmount = amount; // * workingTime?.workingDatesInRange.actualHous;
      } else if (type === 'Daily') {
        value = workingTime?.workingDatesInRange.actualHous;

        defaultValue = value;
        defaultAmount = amount / 8;
        fixedAmount = defaultValue * defaultAmount; // * workingTime?.workingDatesInRange.actualDays;
      } else {
        value = workingTime?.workingDatesInRange.actualHous;
        defaultValue = value;
        defaultAmount = amount / value;
        fixedAmount = amount; //(amount / workingTime?.workingDatesInRange?.totalHous) * workingTime?.workingDatesInRange.actualHous;
      }
    }

    if (id === 'L4') {
      const NUMBER_OF_WEEK_IN_YEAR = 52;
      const NUMBER_DAYS_REQUIRED_TO_WORK_IN_A_WEEK = 5;
      const numberOfDays = Number(value) / 8;
      let amountEncash = 0;

      if (type === 'Monthly') {
        amountEncash =
          ((12 * baseSalary) / (NUMBER_DAYS_REQUIRED_TO_WORK_IN_A_WEEK * NUMBER_OF_WEEK_IN_YEAR)) * numberOfDays;
      } else {
        amountEncash =
          (baseSalaryHourly +
            (baseSalaryHourly * (workingHours * NUMBER_DAYS_REQUIRED_TO_WORK_IN_A_WEEK)) /
              (NUMBER_DAYS_REQUIRED_TO_WORK_IN_A_WEEK * NUMBER_OF_WEEK_IN_YEAR)) *
          value;
      }
      amount = amountEncash;
      defaultAmount = amountEncash;
    }

    if (id === 'M3') {
      return {
        name: 'Basic Salary',
        cpf: 'OW',
        category: 'Basic Salary',
        amount: 0,
        defaultAmount: 0,
        value,
        defaultValue,
        type,
        id,
      };
    }
    // if (id === 'M4') {
    //   itemName = 'CDAC / MBMF/ SINDA/ECF';
    // }

    // if (id === 'M5') {
    //   itemName = 'Community Chest Contribution';
    // }
    if (['M2', 'M8', 'M3'].includes(id)) {
      defaultValue = value;
    }

    if (id === 'M8') {
      const rateHourly = workingTime?.workingDatesInRange?.overTimeRateHourly;
      return {
        name: itemName,
        cpf,
        category,
        amount: value * rateHourly,
        defaultAmount: rateHourly,
        value,
        defaultValue,
        rateHourly,
        type,
        id,
      };
    }
    return {
      name: itemName,
      cpf: id === 'M1' ? 'OW' : cpf,
      category,
      amount,
      defaultAmount,
      fixedAmount,
      value,
      defaultValue,
      type,
      id,
      baseSalaryHourly,
    };
  });

  let consolidatedString = overtimeItems.map((item) => formatHours(item.value)).join('/');
  let totalAmount = overtimeItems.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);

  const overtimeTotalItem = {
    id: 'M8',
    name: 'Overtime Pay',
    category: 'Overtime',
    cpf: 'OW',
    amount: totalAmount,
    displayType: 'overtime',
    value: consolidatedString + ' Hours',
  };

  let basicSalaryIndex = result.findIndex((item) => item.name === 'Basic Salary');
  if (basicSalaryIndex === -1) {
    basicSalaryIndex = payItems.length;
  } else {
    basicSalaryIndex += 1;
  }
  const itemsBeforeBasicSalary = result.slice(0, basicSalaryIndex);
  const itemsAfterBasicSalary = result.slice(basicSalaryIndex);
  const resultWithTotalOvertime = [
    ...itemsBeforeBasicSalary,
    overtimeTotalItem,
    ...overtimeItems,
    ...itemsAfterBasicSalary,
    // {
    //   name: 'CPF contribution',
    //   cpf: '',
    //   category: 'Deduction',
    //   amount: 0,
    //   defaultAmount: 0,
    //   value: 0,
    //   type,
    //   id: 'M10',
    // },
  ];

  return resultWithTotalOvertime;
};

export const getLeaveAmount = (item, payMonthly, workingTime, defaultWorkingHours = 8) => {
  const { workingDatesInRange: { totalHous = 0 } = {} } = workingTime;
  const hourSalary = getBaseSalaryPerHour(payMonthly);

  // let monthlySalary = payMonthly?.fixedAmount > 0 ? payMonthly.fixedAmount : payMonthly?.defaultAmount || 0;

  const leaveHours = item?.defaultValue > -1 ? item?.defaultValue : item?.value;
  return hourSalary * leaveHours;
  // if (payMonthly.type === 'Daily') {
  //   return (payMonthly?.amount / (defaultWorkingHours || 8)) * leaveHours;
  // }
  // if (payMonthly.type === 'Hourly') {
  //   return monthlySalary * leaveHours;
  // } else {
  //   return totalHous ? (monthlySalary * leaveHours) / totalHous : 0;
  // }
};

export const getAddition = (payItem, payMonthly) => {
  const monthlySalary = payMonthly?.amount > 0 ? payMonthly.amount : payMonthly?.defaultAmount || 0;
  if (payItem?.unit === 'Amount') {
    return parseFloat(payItem.value);
  }
  return payItem?.unit === 'Percentage' ? (monthlySalary * payItem.value) / 100 : payItem.value;
};

export const getBasicSalary = (payItems, workingTime = {}, type, isAttendance) => {
  const m1Salary = payItems?.[0];
  // const salaryPay = m1Salary?.amount > 0 ? m1Salary.amount : (m1Salary?.defaultAmount || 0);
  const salaryUnit = getBaseSalaryPerHour(m1Salary);
  const workingTimeInPeriod = +m1Salary?.value || 0;
  const { workingDatesInRange: { actualHous = 0, totalHous = 0 } = {} } = workingTime;

  const unPaidLeave = payItems.filter((item) => item?.id === 'M2' && item?.category.includes('Deduction')); // M2b

  const unPaidLeaveHours = +(unPaidLeave.length > 0
    ? unPaidLeave[0]?.defaultValue > -1
      ? unPaidLeave[0]?.defaultValue
      : unPaidLeave[0]?.value
    : 0);
  return salaryUnit * (workingTimeInPeriod - unPaidLeaveHours);
};

const getCpfRate = (dob, tw, ct) => {
  const age = getAge(dob) || 55;
  let rate = 5;
  if (ct === 1) {
    if (tw > 50 && tw <= 500) {
      if (age <= 55 || (age > 55 && age <= 60) || (age > 60 && age <= 65) || (age > 65 && age <= 70)) {
        rate = 0;
      }
      if (age > 70) {
        rate = 5;
      }
    }

    if (tw > 500 && tw <= 750) {
      if (age <= 55) {
        rate = 60;
      }
      if (age > 55 && age <= 60) {
        rate = 48;
      }
      if (age > 60 && age <= 65) {
        rate = 31.5;
      }
      if (age > 65 && age <= 70) {
        rate = 22.5;
      }
      if (age > 70) {
        rate = 15;
      }
    }

    if (tw > 750) {
      if (age <= 55) {
        rate = 20;
      }
      if (age > 55 && age <= 60) {
        rate = 16;
      }
      if (age > 60 && age <= 65) {
        rate = 10.5;
      }
      if (age > 65 && age <= 70) {
        rate = 7.5;
      }
      if (age > 70) {
        rate = 5;
      }
    }
  }

  if (ct === 2) {
    if (tw > 50 && tw <= 500) {
      rate = 0;
    }

    if (tw > 500 && tw <= 750) {
      rate = 15;
    }

    if (tw > 750) {
      rate = 5;
    }
  }

  if (ct === 3) {
    if (tw > 50 && tw <= 500) {
      rate = 0;
    }

    if (tw > 500 && tw <= 750) {
      if (age <= 55) {
        rate = 45;
      }
      if (age > 55 && age <= 60) {
        rate = 37.5;
      }
      if (age > 60 && age <= 65) {
        rate = 22.5;
      }
      if (age > 65 && age <= 70) {
        rate = 15;
      }
      if (age > 70) {
        rate = 15;
      }
    }

    if (tw > 750) {
      if (age <= 55) {
        rate = 15;
      }
      if (age > 55 && age <= 60) {
        rate = 12.5;
      }
      if (age > 60 && age <= 65) {
        rate = 7.5;
      }
      if (age > 65) {
        rate = 5;
      }
    }
  }
  return rate;
};

const getCpfERRate = (dob, tw, ct, allowFullEmployerCPF) => {
  const age = getAge(dob) || 55;
  let rate = 5;
  if (tw > 50) {
    if (ct === 1 || allowFullEmployerCPF) {
      if (age <= 55) {
        rate = 17;
      }
      if (age > 55 && age <= 60) {
        rate = 15;
      }
      if (age > 60 && age <= 65) {
        rate = 11.5;
      }
      if (age > 65 && age <= 70) {
        rate = 9;
      }
      if (age > 70) {
        rate = 7.5;
      }
    }

    if (ct === 2) {
      if (age <= 55) {
        rate = 4;
      }
      if (age > 55 && age <= 60) {
        rate = 4;
      }
      if ((age > 60 && age <= 65) || (age > 65 && age <= 70) || age > 70) {
        rate = 3.5;
      }
    }

    if (ct === 3) {
      if (age <= 55) {
        rate = 9;
      }
      if (age > 55 && age <= 60) {
        rate = 6;
      }
      if ((age > 60 && age <= 65) || (age > 65 && age <= 70) || age > 70) {
        rate = 3.5;
      }
    }
  }
  return rate;
};

export const getCpfValue = (dob, tw, ct, aw, ow, owCeiling) => {
  const rate = getCpfRate(dob, tw, ct) / 100;
  let result = 0;

  switch (true) {
    case tw > 50 && tw <= 500:
      result = rate * tw;
      break;
    case tw > 500 && tw <= 750:
      result = rate * (tw - 500);
      break;
    case tw > 750:
      result = rate * (aw + Math.min(ow, owCeiling));
      break;
    default:
      result = 0;
  }
  return result;
};

export const getCpfERValue = (dob, tw, ct, allowFullEmployerCPF, aw, ow, owCeiling) => {
  const rate = getCpfERRate(dob, tw, ct, allowFullEmployerCPF) / 100;
  let result = 0;
  switch (true) {
    case tw > 50 && tw <= 500:
      result = rate * tw;
      break;
    case tw > 500 && tw <= 750:
      result = rate * tw;
      break;
    case tw > 750:
      result = rate * (aw + Math.min(ow, owCeiling));
      break;
    default:
      result = 0;
  }
  return result;
};

export const getSubTotal = (
  payItems = [],
  workingTime,
  type,
  dob,
  excludeCPFContributions,
  allowFullEmployerCPF,
  ct,
  owCeiling,
  isAttendance,
  sdlAmount
) => {
  const basicSalary = getBasicSalary(payItems, workingTime, type, isAttendance);
  const overTime = payItems.find((item) => item?.id === 'M8')?.amount || 0;
  const l4 = payItems.find((item) => item?.id === 'L4')?.amount || 0;
  const unPaidLeave = +payItems.find((item) => item?.id === 'M2' && item?.category.includes('Deduction'))?.amount || 0; // M2b
  // const m9aList = payItems
  //   .filter((item) => item?.id === 'M9' && item?.cPF !== 'NoWages')
  //   .map((item) => {
  //     const rate = item?.category === 'Deduction' ? -1 : 1;
  //     return Number(item?.amount) * rate;
  //   });
  const m9a1List = payItems
    .filter((item) => item?.id === 'M9' && item?.cPF === 'OrdinaryWages')
    .map((item) => {
      const rate = item?.category === 'Deduction' ? -1 : 1;
      return Number(item?.amount) * rate;
    });
  const m9a2List = payItems
    .filter((item) => item?.id === 'M9' && item?.cPF === 'AdditionalWages')
    .map((item) => {
      const rate = item?.category === 'Deduction' ? -1 : 1;
      return Number(item?.amount) * rate;
    });
  const m9bList = payItems
    .filter((item) => item?.id === 'M9' && item?.cPF === 'NoWages')
    .map((item) => {
      const rate = item?.category === 'Deduction' ? -1 : 1;
      return Number(item?.amount) * rate;
    });
  const m9a1 = formatFixedNumber(sum(m9a1List));
  const m9a2 = sum(m9a2List); // aw
  const m9b = formatFixedNumber(sum(m9bList));

  const m4 = formatFixedNumber(payItems.find((item) => item?.id === 'M4')?.amount || 0);
  const m5 = formatFixedNumber(payItems.find((item) => item?.id === 'M5')?.amount || 0);
  const m6 = payItems.find((item) => item?.id === 'M6')?.amount || 0;
  const m7 = formatFixedNumber(payItems.find((item) => item?.id === 'M7')?.amount || 0);
  const baseHourSalary = getBaseSalaryPerHour(payItems[0]);
  const lateless = payItems.find((item) => item.id === 'M2a' && item.name === 'Total Lateness');
  const m2a = +baseHourSalary * +(lateless?.value || 0);
  const earlyOut = payItems.find((item) => item.id === 'M2b' && item.name === 'Total Early Out');
  const m2b = +baseHourSalary * +(earlyOut?.value || 0);

  const ow = basicSalary + overTime + m9a1;
  const grossSalary = ow + m9a2 + l4;
  const tw = grossSalary - unPaidLeave; // tw
  const m10a = payItems.find((item) => item?.id === 'M10A')?.amount || 0;
  const m10b = formatFixedNumber(getCpfERValue(dob, tw, ct, allowFullEmployerCPF, m9a2, ow, owCeiling));

  // sdlAmount as m6
  const total = formatFixedNumber(grossSalary) - m4 - m5 - m6 + m7 + m9b - m10a - m2a - m2b;

  return { total: formatFixedNumber(total), cpf: m10a, m10b, grossSalary };
};

export const getSubTotalAmount = (rawData) => {
  if (!rawData || rawData.length === 0) return;
  const item = rawData[0] || {};

  const {
    workingTime = {},
    payTemplateType: type = '',
    dob = '',
    excludeCPFContributions = false,
    allowFullEmployerCPF = false,
    ct = 0,
    ordinaryWages: owCeiling = 0,
    isTurnOnAttendLog: isAttendance = false,
    payItems,
  } = item || {};

  const basicSalary = getBasicSalary(payItems, workingTime, type, isAttendance);
  const baseHourSalary = getBaseSalaryPerHour(payItems[0]);
  const overTime = payItems.find((item) => item?.id === 'M8')?.amount || 0;
  const unPaidLeave = +payItems.find((item) => item?.id === 'M2' && item?.category.includes('Deduction'))?.amount || 0; // M2b

  const absences = payItems.filter((item) => item.id === 'M2' && item.name === 'Absence');
  const absence = absences.reduce((prev, cur) => prev + getLeaveAmount(cur, payItems?.[0], workingTime), 0);
  const m9a1List = payItems
    .filter((item) => item?.id === 'M9' && item?.cPF === 'OrdinaryWages')
    .map((item) => {
      const rate = item?.category === 'Deduction' ? -1 : 1;
      return Number(item?.amount) * rate;
    });
  const m9a2List = payItems
    .filter((item) => item?.id === 'M9' && item?.cPF === 'AdditionalWages')
    .map((item) => {
      const rate = item?.category === 'Deduction' ? -1 : 1;
      return Number(item?.amount) * rate;
    });
  const m9bList = payItems
    .filter((item) => item?.id === 'M9' && item?.cPF === 'NoWages')
    .map((item) => {
      const rate = item?.category === 'Deduction' ? -1 : 1;
      return Number(item?.amount) * rate;
    });
  const m9a1 = sum(m9a1List);
  const m9a2 = sum(m9a2List); // aw
  const m9b = sum(m9bList);

  const m4 = payItems.find((item) => item?.id === 'M4')?.amount || 0;
  const m5 = payItems.find((item) => item?.id === 'M5')?.amount || 0;
  const m6 = payItems.find((item) => item?.id === 'M6')?.amount || 0;
  const m7 = payItems.find((item) => item?.id === 'M7')?.amount || 0;

  const lateless = payItems.find((item) => item.id === 'M2a' && item.name === 'Total Lateness');
  const m2a = +baseHourSalary * +(lateless?.value || 0);

  const earlyOut = payItems.find((item) => item.id === 'M2b' && item.name === 'Total Early Out');
  const m2b = +baseHourSalary * +(earlyOut?.value || 0);

  const l4 = payItems.find((item) => item?.id === 'L4')?.amount || 0; //Leave encashment

  const ow = basicSalary + overTime + m9a1 - absence;
  const grossSalary = ow + m9a2 + l4;
  const tw = grossSalary - unPaidLeave; // tw
  const m10b = getCpfERValue(dob, tw, ct, allowFullEmployerCPF, m9a2, ow, owCeiling);
  const m10a = payItems.find((item) => item?.id === 'M10A')?.amount || 0;

  let total = grossSalary - m4 - m5 - m6 + m7 + m9b - m10a - m2a - m2b;
  total = formatFixedNumber(total);

  return { total, cpf: m10a, m10b, grossSalary };
};

export const getPayItemUnit = (item) => {
  if (['M1', 'M2', 'M2a', 'M2b', 'L4'].includes(item?.id)) {
    //return item?.type === 'Hourly' ? 'Hours' : 'Days';
    //EKE-845: user hourly to calculate basic salary instead of Daily.
    return 'Hours';
  }
  if (item?.id === 'M8a' || item?.id === 'M8b' || item?.id === 'M8c' || item?.id === 'M8d') return 'Hours';
  if (item?.id === 'M9') return item?.unit;
  return '$';
};

export const datePattern = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export const isWeekday = (date) => date.getDay() % 6 !== 0;
export const addDaysToDate = (date, n) => {
  const d = new Date(date);
  d.setDate(d.getDate() + n);
  return d;
};

export const dateDifferenceInWeekdays = (startDate, endDate) =>
  Array.from({ length: (endDate - startDate) / (1000 * 60 * 60 * 24) }).filter((_, i) =>
    isWeekday(addDaysToDate(startDate, i + 1))
  ).length + 1;

export const dateDifferenceInHours = (dateInitial, dateFinal) => (dateFinal - dateInitial) / 3_600_000;

const getTime = (hours, minute) => {
  let numH = Number(hours);
  if (!numH) return '01:00 AM';
  let type = 'AM';
  if (numH > 12) {
    numH = numH - 12;
    type = 'PM';
  }
  return `${numH}:${minute} ${type}`;
};

export const getDisableTime = (times = '') => {
  const [start = '', end = ''] = times.split('-');
  const [startHours = '', startMinute = ''] = start.split(':');
  const [endHours = '', endMinute = ''] = end.split(':');
  const disableStartHours = startMinute === '00' ? Number(startHours) - 1 : startHours;
  const disableStartMinute = startMinute === '00' ? 59 : Number(startMinute) - 1;

  const disableEndHours = endMinute === '59' ? Number(endHours) + 1 : endHours;
  const disableEndMinute = endMinute === '59' ? 0 : Number(endMinute) + 1;

  return {
    start: `01:00 AM - ${getTime(disableStartHours, disableStartMinute)}`,
    end: `${getTime(disableEndHours, disableEndMinute)} - 12:59 PM`,
  };
};

export const checkFileValid = (file) => {
  if (!file) return;
  const extension = file.name.split('.').pop();
  const size = file.size;
  const extensionInvalid = ['jpg', 'png', 'pdf', 'svg', 'jpeg', 'gif'];
  if (!extensionInvalid.some((x) => x === extension.toLowerCase())) {
    Swal.fire({
      title: 'Error!',
      text: 'File upload failed. Please upload file in jpg, png, pdf, jpeg, gif, svg format.',
      type: 'error',
      icon: 'error',
    });
    return false;
  }
  if (size > 2 * 1024 * 1024) {
    Swal.fire({
      title: 'Error!',
      text: 'File upload failed. The maximum file size is 2MB.',
      type: 'error',
      icon: 'error',
    });
    return false;
  }
  return true;
};

export const getWeekDayList = (startDate, endDate) => {
  let days = [];
  let end = new Date(endDate);
  for (let start = new Date(startDate); start <= end; start.setDate(start.getDate() + 1)) {
    let day = start.getDay();
    // if (day != 6 && day != 0) {
    days.push(new Date(start));
    // }
  }
  return days;
};

const getEmployeeData = (data) => {
  return {
    ...data,
    age: getAge(data?.birthDay),
    organization: data?.organization?.organizationName,
    department: data?.department?.name,
  };
};

export const getAppraisalDetail = (data, employeeDefault) => {
  const result = [];
  if (!data) return result;
  const employee = getEmployeeData(employeeDefault);
  Object.keys(data).forEach(function (key) {
    if (data[key]?.required) {
      result.push({
        title: DETAIL_TITLE[key],
        name: key,
        type: DETAIL_COMP[key],
        value: employee[EMPLOYEE_INFO[key]],
      });
    }
  });
  return result;
};

export function dataURLtoFile(dataUrl, filename) {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const getBaseHourlyRateAuto = (value = '') => {
  // (Monthly pay x 12 months) / (52 weeeks x 44hours)
  let monthlyPay = value.replace('.00', '').replace(/,/g, '');
  monthlyPay = parseInt(monthlyPay) || 0;
  const result = (monthlyPay * 12) / (52 * 44) || 0;
  return result.toFixed(2);
};

export const getMonthly = (item, workingTime, payTemplateType) => {
  if (payTemplateType) {
    const { workingDatesInRange: { totalHous = 0 } = {} } = workingTime;

    let monthlySalary = item?.fixedAmount > 0 ? item.fixedAmount : item?.defaultAmount || 0;
    const hours = item?.defaultValue > -1 ? item?.defaultValue : item?.value;
    const hourSal = !getBaseSalaryPerHour(item) ? item?.amount / item?.defaultValue : getBaseSalaryPerHour(item);
    monthlySalary = hours * hourSal;
    // if (payTemplateType === 'Hourly') {
    //   monthlySalary = monthlySalary * hours;
    // }
    // if (payTemplateType === 'Monthly' || payTemplateType === 'Daily') {
    //   if (totalHous > 0) {
    //     monthlySalary = (monthlySalary * hours) / totalHous;
    //   }
    // }

    return monthlySalary;
  } else {
    return item.fixedAmount * (item?.defaultValue > -1 ? item?.defaultValue : item?.value);
  }
};

export const formatFixedNumber = (number) => {
  if (!number) {
    return 0;
  }

  return parseFloat(number.toFixed(2));
};

export const formatHours = (number) => {
  if (!isNaN(number) && !isNaN(parseFloat(number))) {
    const tmp = parseFloat(number);
    if (tmp % 1 !== 0) {
      return tmp.toFixed(2);
    }
    return tmp.toString();
  }

  return number;
};

export const getPayCurrency = (payTemplateType, currencySymbol = '$') => {
  let type = 'month';
  if (payTemplateType === 'Daily') {
    type = 'day';
  } else if (payTemplateType === 'Hourly') {
    type = 'hr';
  }
  return `${currencySymbol}/${type}`;
};

export const roundUpNumber = (value, decimals) => {
  if (Number.isInteger(value)) {
    return value;
  }
  const decimalFixedNumber = value.toFixed(decimals);
  const extraFixedNumber = value.toFixed(decimals + 1);
  const lastDecimalNumber = parseInt(decimalFixedNumber.slice(-1));
  const lastExtraNumber = parseInt(extraFixedNumber.slice(-1));
  if (lastExtraNumber > 0) {
    return decimalFixedNumber.slice(0, -1) + (lastDecimalNumber + 1);
  }
  return decimalFixedNumber;
};

export const convertServerTimeToLocalTime = (serverDateTime, type = 'full') => {
  let data = '';

  if (!serverDateTime) {
    return data;
  }

  const localDateTime = new Date(serverDateTime);
  switch (type) {
    case 'date':
      data = localDateTime.toLocaleDateString('zh-Hans-CN');
      break;
    case 'time':
      data = localDateTime.toLocaleTimeString();
      break;
    default:
      data = localDateTime.toLocaleString();
  }

  return data;
};

export const getGMTOffset = (timezone) => {
  const date = new Date();
  const options = { timeZone: timezone, timeZoneName: 'shortOffset' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formatted = formatter.formatToParts(date);

  const gmtPart = formatted.find((part) => part.type === 'timeZoneName');

  if (gmtPart) {
    return gmtPart.value.replace(':', '');
  }

  return null;
};

export const mappingPlanFeatureWithLayout = (planFeatures, employeeModuleAccess) => {
  const employeeModuleAccessList = ['claims', 'leaves', 'attendance', 'desk_booking', 'room_booking', 'payroll'];
  const auth_user = localStorage.getItem('auth_user') ? JSON.parse(localStorage.getItem('auth_user')) : {};
  const isAdmin = auth_user?.isAdmin;
  const naviList = isAdmin ? navigations : staffNavigations;
  const filteredNavigationsFollowPlan = naviList.filter(
    (navItem) => defaultCommonRoute?.includes(navItem.path) || planFeatures?.includes(navItem.module)
  );

  const filterNavigationFollowEmployeeAccess = filteredNavigationsFollowPlan.filter((item) => {
    if (!employeeModuleAccessList.includes(item.module)) {
      return true;
    }

    if (employeeModuleAccess?.length === 0) return false;
    else {
      if (employeeModuleAccessList.includes(item.module)) {
        return true;
      }
      return false;
    }
  });

  return isAdmin ? filteredNavigationsFollowPlan : filterNavigationFollowEmployeeAccess;
};

export function generateRandomKey(length = 5) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  const timestamp = Date.now();
  return `${randomString}-${timestamp}`;
}
