import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const styles = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 'calc(50% - 20px)',
  margin: 'auto',
  height: '40px',
  width: '40px',
  zIndex: 324324324,
};

const NewLoading = ({ size = 48, color = '#02B9B0', style }) => {
  return (
    <div style={{...styles, ...style}}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: size, color }} spin />} size="large" />
    </div>
  );
};

export default NewLoading;
