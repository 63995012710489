import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

// const Dashboard = lazy(() => import('./Dashboard'));
const NewDashboard = lazy(() => import('./NewDashboard'));


const dashboardRoutes = [
  {
    path: '/dashboard',
    component: NewDashboard,
    auth: authRoles.admin,
  },
];

export default dashboardRoutes;
