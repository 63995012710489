import '../fake-db';
import React, { Suspense, useEffect, useState } from 'react';
import '../styles/app/app.scss';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import AppContext from './appContext';
import history from '@history';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import routes from './RootRoutes';
import { Store } from './redux/Store';
import { renderRoutes } from 'react-router-config';
import Auth from './auth/Auth';
import RootRoutes from './RootRoutes';
import { NewLoading } from '@gull';
import PlatformAlertModal from './components/PlatformAlertModal';
import { isMobile } from '@utils';

function App() {
  const queryClient = new QueryClient();
  const [visible, setVisible] = useState(false);
  const pathname= window.location.pathname;
  const responsivePathName = ['session', 'login-google-fail'];

  useEffect(() => {
    const isInResponsivePath = responsivePathName.some((path) => pathname.includes(path));
    if (isMobile() && !isInResponsivePath && pathname !== '/') {
      setVisible(true);
    }
  }, []);

  const onClose = () => setVisible(false);
  return (
    <AppContext.Provider value={{ routes }}>
      <QueryClientProvider client={queryClient}>
        <Provider store={Store}>
          <Auth>
            <Suspense fallback={<NewLoading />}>
              <PlatformAlertModal visible={visible} onClose={onClose} />
              <Router history={history}>{renderRoutes(RootRoutes)}</Router>
            </Suspense>
          </Auth>
        </Provider>
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
