import React, { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Redirect } from 'react-router-dom';

// const OrganizationDetail = lazy(() => import('./OrganizationDetail'));
const OrganizationList = lazy(() => import('./redesign/OrganizationList'));
const RedesignOrganizationDetailStructure = lazy(() => import('./redesign/OrganizationDetailStructure'))
// const OrganizationCreate = lazy(() => import('./OrganizationCreate'));
const OrganizationStructure = lazy(() => import('./OrganizationStructure/OrganizationStructure'));
const BranchsOutlets = lazy(() => import('./Branchs/Branchs'));
const BranchDetail = lazy(() => import('./Branchs/BranchDetail'));
const BranchAdd = lazy(() => import('./Branchs/BranchAdd'));
const Announcements = lazy(() => import('./Announcements/Announcements'));
const AnnouncementDetail = lazy(() => import('./Announcements/AnnouncementDetail'));
const AnnouncementCreate = lazy(() => import('./Announcements/AnnouncementCreate'));
const WorkingHours = lazy(() => import('./WorkingHours/WorkingHours'));
const HolidayWorkingList = lazy(() => import('./HolidayWorking/HolidayWorkingList'));
const HolidayWorkingDetail = lazy(() => import('./HolidayWorking/HolidayWorkingDetail'));
const HolidayWorkingCreate = lazy(() => import('./HolidayWorking/HolidayWorkingCreate'));
const AssistantAdminList = lazy(() => import('./AssistantAdmin/AssistantAdminList'));
const AssistantAdminDetail = lazy(() => import('./AssistantAdmin/AssistantAdminDetail'));
const AssistantAdminCreate = lazy(() => import('./AssistantAdmin/AssistantAdminCreate'));

const organizationRoutes = [
  {
    path: '/organization/info',
    component: OrganizationList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/organization-structure',
    component: OrganizationStructure,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/organization-structure/create',
    component: RedesignOrganizationDetailStructure,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/organization-structure/:id',
    component: RedesignOrganizationDetailStructure,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/branches-outlets',
    component: BranchsOutlets,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/branches-outlets/create',
    component: BranchAdd,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/branches-outlets/:id',
    component: BranchDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/announcements',
    component: Announcements,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/announcements/create',
    component: AnnouncementCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/announcements/:id',
    component: AnnouncementDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/working-hours',
    component: WorkingHours,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/holiday-work-list',
    component: HolidayWorkingList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/holiday-work-list/create',
    component: HolidayWorkingCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/holiday-work-list/:id',
    component: HolidayWorkingDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/asst-admin',
    component: AssistantAdminList,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/asst-admin/create',
    component: AssistantAdminCreate,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization/asst-admin/:id',
    component: AssistantAdminDetail,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: '/organization',
    exact: true,
    component: () => <Redirect to="/organization/info" />,
  },
];

export default organizationRoutes;
