import axios from 'app/services/axios';
import { setLayoutNavigations } from './LayoutActions';
import { mappingPlanFeatureWithLayout } from '../../../../src/@utils';
export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';
export const RELOAD_ORGANIZATION_SIDEBAR = 'RELOAD_ORGANIZATION_SIDEBAR';
export const SELECT_ORGANIZATION_SUCCESS = 'SELECT_ORGANIZATION_SUCCESS';

export const selectOrganization = (organizationId) => {
  return (dispatch) => {
    try {
      dispatch({ type: SELECT_ORGANIZATION });
      localStorage.setItem('OrganizationId', organizationId);
      axios.get(`/api/v1/organization/organ/${organizationId}`).then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          localStorage.setItem('organizationDetail', JSON.stringify(data));
          const employeeModuleAccess = data?.employeeModuleAccess || [];
          const updateNavigations = mappingPlanFeatureWithLayout(data?.plan?.features, employeeModuleAccess);
          dispatch(setLayoutNavigations(updateNavigations));
          dispatch({
            type: SELECT_ORGANIZATION_SUCCESS,
            payload: { data },
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const reloadOrgSidebar = (data) => {
  return (dispatch) => {
    dispatch({ type: RELOAD_ORGANIZATION_SIDEBAR, payload: { data } });
  };
};
