import {
  SELECT_ORGANIZATION,
  SELECT_ORGANIZATION_SUCCESS,
  RELOAD_ORGANIZATION_SIDEBAR,
} from '../actions/OrganizationAction.js';

const initialState = {
  selectedOrganization: {},
  reload: false,
};

const OrganizationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SELECT_ORGANIZATION:
      return (state = {
        ...state,
      });

    case SELECT_ORGANIZATION_SUCCESS:
      return (state = {
        ...state,
        selectedOrganization: action.payload.data,
      });

    case RELOAD_ORGANIZATION_SIDEBAR:
      return (state = {
        ...state,
        reload: action.payload.data.reload,
      });

    default: {
      return state;
    }
  }
};

export default OrganizationReducer;
